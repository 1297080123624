import { SVGAttributes } from "react";

export function Section5() {
  const items = [
    {
      user: "@不屈的韭菜",
      title: "量化初学者",
      content:
        "\t我是一个期货的初学者，一直想学习量化技术，试了很多平台，发现他们在策略构建上都需要一定的程序编辑能力。直到遇到了多元量化，它的策略编辑只需要输入相应的参数，更重要的是后期调试修改非常方便，不需要为程序编辑和代码应用多投入额外精力。让我从手动党踏入了量化界，感谢多元量化！",
    },
    {
      user: "@不期而遇",
      title: "期货量化交易员",
      content:
        "\t做量化策略，最主要的就是需要不停回测，确认策略的通过性。多元量化的测试器是我当前用过的回测软件中最方便的一个，能快速测试、快速检查，测试报告全面，详细到每个条件的启动状况。而且还配备长时期的深度回测，长短配合，策略调整和验证的利器。推荐给每位做量化交易的朋友，赞！",
    },
    {
      user: "@Micheal",
      title: "量化分析师",
      content:
        "\t一直想找一款使用简单功能全面，又能对当前市场数据行情进行实时分析的软件。朋友推荐了多元量化，里面的智选和指标分析正式我想要的。\n\t智选筛选的合约都是当前交易的热门品种，指标分析实时显示当前多空状况，无论策略构建还是手动交易都是很好的技术参考。",
    },
    {
      user: "@阿强",
      title: "期货交易用户",
      content:
        "\t试用了多元量化App，虽然还没进行深入研究，目前最大的体会就是它的云管理方式。软件容量不大，但支持的平台全面，安装简单。所有的操作和参数都是云平台管理，随登随用，不受设备存储限制。是一款非常方便的量化软件",
    },
    {
      user: "@小不点",
      title: "投研学者",
      content:
        "\t多元量化的“模板”很赞，可用可改可参考！市面的量化软件能提供可用的样例很少，能用能改的更少，各个都把策略当宝一样难得分享。这里的模版能清晰完整的查看，相当于完全共享给用户，用户只要根据当前行情状况和自身需求，对策略稍做调整就能应用，即方便应用也方便学习，对初学者来说，是一个很好的入门支持！",
    },
    {
      user: "@陈老师",
      title: "财经学院老师",
      content: `\t这是一款推荐给学生作为量化入门的平台。总的来说这款软件有以下几个特点：
1、界面简介，使用方面
2、零代码策略创建，非常适合量化初学者。
3、报告详尽，方便分析，回测功能也非常健全，基本可以做到随时调整随时测试。
4、提供的标的筛选和分析也很有参考价值。`,
    },
  ];
  return (
    <section id="section5" class="relative overflow-hidden">
      <div class="main-container py-8 sm:py-20">
        <Mask class="absolute left-0 top-0" />
        <Mask class="absolute right-0 top-0 rotate-180" />
        <h3 class="text-3xl sm:text-4xl font-semibold text-center mb-8 sm:mb-16">
          和他们一起用多元量化
        </h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-5 text-xs sm:text-sm">
          {items.map((item) => (
            <div class="rounded-lg shadow-md bg-white p-4">
              <div>
                <strong class="mr-4">{item.user}</strong>
                <span class="text-[#075792]">{item.title}</span>
              </div>
              <pre class="mt-4 whitespace-pre-wrap leading-normal text-gray-500">
                {item.content}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function Mask(props: Omit<SVGAttributes, "ref">) {
  return (
    <svg
      width="610"
      height="824"
      viewBox="0 0 610 824"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.85">
        <mask
          id="mask0_295_1058"
          style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="610"
          height="824"
        >
          <rect width="610" height="824" fill="url(#pattern0_295_1058)" />
        </mask>
        <g mask="url(#mask0_295_1058)">
          <rect width="636" height="824" fill="url(#paint0_linear_295_1058)" />
        </g>
      </g>
      <defs>
        <pattern
          id="pattern0_295_1058"
          patternContentUnits="objectBoundingBox"
          width="0.0983607"
          height="0.0728155"
        >
          <use
            xlink:href="#image0_295_1058"
            transform="scale(0.000819672 0.000606796)"
          />
        </pattern>
        <linearGradient
          id="paint0_linear_295_1058"
          x1="-223.113"
          y1="412.517"
          x2="558.21"
          y2="438.361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#97C9FF" />
          <stop offset="1" stop-color="#F3F9FF" />
        </linearGradient>
        <image
          id="image0_295_1058"
          width="120"
          height="120"
          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAYAAAA5ZDbSAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFWSURBVHgB7d27UcNAFAXQt6uAIaMHPjEN8HEluBObSoBK+FQAKZ8eyDwE9mIzQOARGELtnhNoNKOX3EDa3UQ3xafrp9lpl9KkRDpMETsR5Wa+KFejg+3LqFAredPqcvvyNkklpn0DJcX0ZHfrPCrSUt50/Tgbdzlf/DY0L4vRaH/7JirQWt7c5XS2aahL3SQq0VrevHyJTzePlcOoRlt583IVfv3D3E7UorG8ebmruN84Vcpt1KKxvHle5pt3jKlcRiVay5tXu8VS4sfQq2fHe/WcDVvLm75u7p5n4+XnaxwpnXysU4vykKJMjyo5LqxrLS8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/1/Ut/3YW6C6uhu3CN7sLh0l3YN6O7cMh0F/bRXThQugt7Z3QXDpbuwjW6C4dNd6HuQgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAer0D3/jlFZijZe4AAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}
