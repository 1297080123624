import classNames from "classnames";
import { useEffect, useRef, useState } from "preact/hooks";
import { links, Page } from "../../page";
import { Header } from "./header";
import { Section1 } from "./section1";
import { Section2 } from "./section2";
import { Section3 } from "./section3";
import { Section4 } from "./section4";
import { Section5 } from "./section5";
import { Section6 } from "./section6";

export function HomePage() {
  return (
    <Page scripts={["index.js"]} path={links.home}>
      <Header />
      <NavBar />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Page>
  );
}

function NavBar() {
  let navHeight = 68;
  if (typeof window != "undefined" && window.innerHeight < 768) {
    navHeight = 48;
  }
  const navs = [
    { name: "视频一览", href: "#section1" },
    { name: "智能工具", href: "#section2" },
    { name: "AI 加持", href: "#section3" },
    { name: "专业开发", href: "#section4" },
    { name: "客户反馈", href: "#section5" },
    { name: "合作伙伴", href: "#section6" },
  ];
  let [active, setActive] = useState(0);
  const animation = useRef(0);
  useEffect(() => {
    const items = navs.map(
      (nav) => document.querySelector(nav.href) as HTMLElement
    );

    function scrollTo(scrollY: number) {
      if (animation.current) return;

      scrollY += navHeight;
      for (var i = 0; i < navs.length; i++) {
        const { offsetTop, offsetHeight } = items[i];
        if (scrollY >= offsetTop && scrollY < offsetTop + offsetHeight) {
          if (active == i) break;

          active = i;
          setActive(i);
          break;
        }
      }
    }

    function onScroll() {
      scrollTo(window.scrollY);
    }

    scrollTo(window.scrollY);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <nav
      class={classNames(
        "h-12 md:h-[68px] bg-white drop-shadow-md sticky top-0 z-10 transition-all"
      )}
    >
      <div class="main-container h-full flex items-center whitespace-nowrap">
        {navs.map((nav, index) => (
          <button
            class="flex-1 md:mx-7 md:flex-none md:text-base xl:mx-8 h-full font-semibold text-xs transition-all"
            onClick={() => {
              const element = document.querySelector(
                navs[index].href
              ) as HTMLElement;
              window.scrollTo({
                top: element.offsetTop - navHeight,
                behavior: "smooth",
              });
              setActive(index);
              clearTimeout(animation.current);
              animation.current = window.setTimeout(() => {
                animation.current = 0;
              }, 1000);
            }}
          >
            <span
              class={classNames(
                "h-full w-fit border-y-4 border-transparent transition-all inline-flex items-center",
                index == active && "border-b-[#2794fd]"
              )}
            >
              {nav.name}
            </span>
          </button>
        ))}
        <div class="hidden lg:flex flex-1 justify-end">
          <a class="btn-primary w-28 h-10 mr-4" href={links.pricing}>
            查看定价
          </a>
          <a class="btn-secondary w-28 h-10" href={links.download}>
            免费试用
          </a>
        </div>
      </div>
    </nav>
  );
}
